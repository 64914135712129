import React from 'react'

import styles from './topArray.module.css'

export default ({top}) => {
  const list = top.map(({companyName, subsectorName, avgScore}, idx) => (
    <div className={styles.box} key={idx}>
      <span className={styles.avg}>{(avgScore / 10).toFixed(1)}</span>
      <h3>{companyName}</h3>
      <span>{subsectorName}</span>
    </div>
  ));

  return (
    <div className={styles.topArray}>
      {list}
    </div>
  );
};
