import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import styles from './header.module.css'
import logo from '../../assets/logo.png'

export default () => {
  const userinfo = useSelector(state => state.authState['TOKENINFO']);

  return (
  <header className={styles.header}>
    <div className={styles.logoContainer}>
      <Link to="/"><img src={logo} alt="logo img" /></Link>
    </div>
    <nav className={styles.list}>
      <ul>
        <li>
          <Link to="/">Se un Gurú</Link>
        </li>
        <li>
          {!userinfo.value
            ? <Link to="/?m=login">Iniciar sesión</Link>
            : <span>{userinfo.value.username}</span>
          }
        </li>
        <li>
          <Link to="/about/">Ayuda</Link>
        </li>
      </ul>
    </nav>
  </header>
  );
};
