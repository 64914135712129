import React from 'react'

import styles from './topics.module.css'

export default props => (
  <div className={styles.topicsContainer}>
    <div className={styles.box}>
      <h3>Usuarios</h3>
      <p>
        Permite encontrar referencias de productos y 
        servicios para tomar mejores decisiones de compra.
      </p>
    </div>
    <div className={styles.box}>
      <h3>Usuarios</h3>
      <p>
        Permite encontrar referencias de productos y 
        servicios para tomar mejores decisiones de compra.
      </p>
    </div>
    <div className={styles.box}>
      <h3>Usuarios</h3>
      <p>
        Permite encontrar referencias de productos y 
        servicios para tomar mejores decisiones de compra.
      </p>
    </div>
  </div>
)
