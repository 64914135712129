
import { handleActions } from 'redux-actions';

const initialState = {
  COMPANIESTOP: { value: [], status: null },
  SUBSECTORSTOP: { value: [], status: null },
};

const actions = {
  'COMPANIESTOP_SUCCESS' (state, action) {
    const [ name, status ] = action.type.split('_')
    return {
      ...state,
      [name]: {status, value: action.payload.companies},
    }
  },

  'COMPANIESTOP_FAILED' (state, action) {
    const [ name, status ] = action.type.split('_')
    return {
      ...state,
      [name]: { status },
    }
  },

  'SUBSECTORSTOP_SUCCESS' (state, action) {
    const [ name, status ] = action.type.split('_')
    return {
      ...state,
      [name]: {status, value: action.payload.subsectors},
    }
  },

  'SUBSECTORSTOP_FAILED' (state, action) {
    const [ name, status ] = action.type.split('_')
    return {
      ...state,
      [name]: { status },
    }
  }
};

export default handleActions(actions, initialState);
