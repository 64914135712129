import { user } from '../libs/api';

export const signin = (form) => async dispatch => {
  const data = new FormData(form);
  data.set('remember', false);
  await user.singin('SIGNIN', data, dispatch);
};

export const loadTokenInfo = () => async (dispatch, getState) => {
  const { authState } = getState()
  const data = new FormData();
  data.set('token', authState.SIGNIN.value);

  await user.loadTokenInfo('TOKENINFO', data, dispatch);
};
