import { urlencodeFormData } from './utils';

const apiLocation = process.env.REACT_APP_API_LOCATION
const defaultConf = { method: 'GET' };

async function request (URI, action, dispatch, config = defaultConf) {
  dispatch({ type: `${action}_PENDING` })
  const response = await fetch(`${apiLocation}${URI}`, config);
  if (response.status === 200) {
    let responseBody = await response.json();
    dispatch({ type: `${action}_SUCCESS`, payload: responseBody });  
  } else {
    dispatch({ type: `${action}_FAILED` });
  }
}

async function post (URI, action, form, dispatch) {
  // const data = new FormData(form);
  // data.set('remember', false); // @TODO
  const encodeData = urlencodeFormData(form);
  console.log(URI)
  
  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    method: "POST",
    body: encodeData
  };
  
  return request(URI, action, dispatch, config);
}

export const user = {
  singin: (action, form, dispatch) => post('auth/basic', action, form, dispatch),
  loadTokenInfo: (action, form, dispatch) => post('auth/loadTokenInfo', action, form, dispatch),
  // @TODO: sigup ....
};
  
export const ranking = {
  companies: (action, dispatch) =>  request('companies/top', action, dispatch),
  subsectors: (action, dispatch) =>  request('topsubsectors', action, dispatch),
  // @TODO: sectors: ...
  // @TODO: subsectors: ...
}
