import React from 'react';
import classnames from 'classnames';

import styles from './popup.module.css';

export default function Popup ({title = '', shadow = true, open = false, onClose, children}) {
  return (
    <div className={classnames({
      [styles.backgroundContent]: true,
      [styles.shadow]: shadow,
      [styles.visible]: open
    })}>
      <div className={styles.popupContainer}>
        <div className={styles.closeBar}>
          <div className={styles.closeTitle}>{title}</div>
          <button onClick={_ => onClose && onClose()}>x</button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
