import React from 'react'

import styles from './carousel.module.css'

export default props => (
  <div className={styles.carouselContainer}>
    <div className={styles.carouselHead}>
      <h3>Gana atractivas recompensas</h3>
      <p>Por evaluar los servicios que utilizas</p>
    </div>

    <form className={styles.searchForm}>
      <input placeholder="Busca una empresa, marca o categoria" className={styles.searchInput} />
      <input type="submit" value="Buscar" className={styles.searchSubmit} />
    </form>
    <p>+ de 30,000 experiencias de usuarios reales para ayudarte a tomar mejores decisiones</p>
  </div>
)
