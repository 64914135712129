import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory} from 'react-router';
import qs from 'query-string';

import styles from './home.module.css';

import Header from '../../components/Header';
import Carousel from '../../components/Carousel';
import Signin from '../../components/Signin';
import Topics from '../../components/Topics';
import TopArray from '../../components/TopArray';

import {
  companiesTOP as companiesTOPAction,
  subsectorsTOP as subsectorsTOPAction
} from '../../actions/rankingActions';

import { loadTokenInfo } from '../../actions/authAction'

export default function Home () {
  const { location } = useHistory();
  const { m = null } = qs.parse(location.search)

  const signin = useSelector(state => state.authState['SIGNIN']);
  const companiesTOP = useSelector(state => state.rankingState['COMPANIESTOP']);
  const subsectorsTOP = useSelector(state => state.rankingState['SUBSECTORSTOP']);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(loadTokenInfo());
    dispatch(companiesTOPAction());
    dispatch(subsectorsTOPAction());
  }, [dispatch]);


  return (
    <div className={styles.home}>
      <Signin open={m === 'login' && !signin.value} /> {/* @TODO: change logic */}
      <Header />
      <Carousel />
      <Topics />

      <div className={styles.ranking}>
        <h2>Marcas mejor evaluadas</h2>
        <TopArray top={companiesTOP.value} />

        <h2>Categorías mejor evaluadas</h2>
        <TopArray top={subsectorsTOP.value} />
      </div>
    </div>
  );
}
