import React from 'react';
import Header from '../components/Header'

export default function About (props) {
  return (
    <div className="App">
    <Header />
     <p>loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren 
     loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren 
     loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren 
     loren loren loren loren loren loren loren loren loren loren loren loren 
     </p>
     <p>loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren 
     loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren 
     loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren loren 
     loren loren loren loren loren loren loren loren loren loren loren loren 
     </p>
    </div>
  );
};
