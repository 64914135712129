
import { handleActions } from 'redux-actions';

const token = sessionStorage.getItem('token') || null

const initialState = {
  SIGNIN: { value: token, status: null },
  TOKENINFO: { value: null, status: null },
};

const actions = {
  'SIGNIN_SUCCESS' (state, action) {
    const [ name, status ] = action.type.split('_')
    sessionStorage.setItem('token', action.payload.token)

    return {
      ...state,
      [name]: {status, value: action.payload.token},
    }
  },

  'SIGNIN_FAILED' (state, action) {
    const [ name, status ] = action.type.split('_')
    return {
      ...state,
      [name]: { status },
    }
  },

  'TOKENINFO_SUCCESS' (state, action) {
    const [ name, status ] = action.type.split('_')
    return {
      ...state,
      [name]: {status, value: action.payload.user},
    }
  },

  'TOKENINFO_FAILED' (state, action) {
    const [ name, status ] = action.type.split('_')
    return {
      ...state,
      [name]: { status },
    }
  },
};

export default handleActions(actions, initialState);
