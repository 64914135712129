import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './reducers/rootReducer';

export default function configure (initialState) {
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(ReduxThunk),
      reduxDevTools
    )
  );

  if (module.hot) {
    module.hot.accept('./reducers/rootReducer', () => {
      const nextReducer = require('./reducers/rootReducer').default;
      store.replaceReducer(nextReducer);
    })
  }

  return store;
}
