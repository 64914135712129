import { handleActions } from 'redux-actions'

const initialState = {
  popup: false
}

const actions = {
  'OPEN_POPUP' (state) {
    return {
      ...state,
      popup: true
    }
  },

  'CLOSE_POPUP' (state) {
    return {
      ...state,
      popup: false
    }
  }
}

export default handleActions(actions, initialState)
