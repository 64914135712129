import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from './pages/Home'
import About from './pages/About'

function App(props) {
  return (
    <Router>
      <Route path="/" exact component={Home} />
      <Route path="/about/" component={About} />
    </Router>
  );
}

export default App;
