import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './signin.module.css';
import Popup from '../Popup';
import { signin as signinAction } from '../../actions/authAction';

export default function Signin ({open = false, onClose}) {
  const signin = useSelector(state => state.authState['SIGNIN']);
  const dispatch = useDispatch();

  const onSubmit = event => {
    event.preventDefault();
    dispatch(signinAction(event.target))
  };

  return (
    <Popup open={open} title='Iniciar sessión' onClose={onClose}>
      <form className={styles.form} onSubmit={onSubmit}>
        <label htmlFor="email">Email</label>
        <input placeholder="email" name="email" type="email" required />
        <label htmlFor="password">Contraseña</label>
        <input placeholder="contraseña" type="password" name="password" required />
        <span>{signin.status ==='FAILED' && 'Las credenciales son incorrectas'}</span>
        <input type="submit" value="Iniciar sessión" />
      </form>
    </Popup>
  );
}
