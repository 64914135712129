import { combineReducers } from 'redux';
import authState from './authState';
import siteState from './siteState';
import rankingState from './rankingState';

export default combineReducers({
  authState,
  siteState,
  rankingState
});
